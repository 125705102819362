import React, { useState, useEffect } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { useAlert } from '../../../alert/useAlert';
import { FaNairaSign } from 'react-icons/fa6';
import { CircularProgress } from '@mui/material';

const MyCharts = () => {
    const [loading, setLoading] = useState(false);
    const [lineChartData, setLineChartData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [user, setUsers] = useState(null)
    const [totalAmount, setTotalAmount] = useState(0);
    const showMessage = useAlert();
    const [amount, setAmount] = useState(null)
    const [today, setToday] = useState(null)
    const [todayBar, setTodayBar] = useState(null)
    const token = localStorage.getItem("token");


    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/receipttoday`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();

                // Transform data for Line Chart
                const lineData = json.map(item => ({
                    name: item.name,
                    uv: item.amount,
                    pv: item.amount / 2,
                    amt: item.amount
                }));

                // Transform data for Bar Chart
                const barData = json.map(item => ({
                    name: item.name,
                    amount: item.amount
                }));

                setLineChartData(lineData);
                setBarChartData(barData);
                const total = json.reduce((acc, item) => acc + item.amount, 0);
                setTotalAmount(total);
            } catch (error) {
                setLoading(false);
                showMessage("Something went wrong", "error");
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);


    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/t_oday`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();

                // Transform data for Line Chart
                const lineData = json.map(item => ({
                    name: item.name,
                    uv: item.amount,
                    pv: item.amount / 2,
                    amt: item.amount
                }));

                // Transform data for Bar Chart
                const barData = json.map(item => ({
                    name: item.name,
                    amount: item.amount
                }));

                setToday(lineData);
                setTodayBar(barData);
                const total = json.reduce((acc, item) => acc + item.amount, 0);
                setAmount(total);
            } catch (error) {
                setLoading(false);
                showMessage("Something went wrong", "error");
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);


    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/plus`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });
                const json = await response.json();
    
                const numbers = json.amount
                const arrayTotal = numbers.reduce((acc, num) => acc + num, 0);
                setUsers(arrayTotal);
            } catch (error) {
                showMessage("Failed to fetch data", 'error');
            } finally {
                setLoading(false);
            }
        };
    
        getDetails();
    }, [token]);

    const Money =(index)=>{
        if(index){
         return index.toLocaleString()
        }

        return <CircularProgress />
    }
    
    return (
        <div className="p-6 space-y-8">
            {/* Line Chart */}
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
                <h3 className='text-xl font-semibold mb-4 text-center'>
                    Total Transactions In a Month: {lineChartData.length}
                </h3>
                <h4 className='text-lg font-medium flex flex-row items-center text-center'>
                    Total Spend In a Month: <FaNairaSign className=' ml-4' />{Money(totalAmount)} 
                </h4>
                <h4 className='text-lg font-medium flex flex-row items-center text-center'>
                    Overall Amount: <FaNairaSign className=' ml-4' />{Money(user)} 
                </h4>
                <h4 className='text-lg font-medium flex flex-row items-center text-center'>
                    Today spent: <FaNairaSign className=' ml-4' />{Money(amount)} 
                </h4>
            </div>

            {/* Bar Chart */}
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
                <h3 className='text-xl font-semibold mb-4 text-center'>Utilities Distribution</h3>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={barChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                        <YAxis tick={{ fontSize: 12 }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="amount" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <h3 className='text-xl font-semibold mb-4 text-center'>
                    Total Transactions Today: {today?.length}
                </h3>
                <ResponsiveContainer width="100%" height={400}>

                    <BarChart data={todayBar}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                        <YAxis tick={{ fontSize: 12 }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="amount" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            
        </div>
    );
};

export default MyCharts;
