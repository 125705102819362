import React from "react";
import { FaShoppingBag, FaPhone, FaWifi, FaBook, FaSalesforce, FaLightbulb } from "react-icons/fa";
import { FaTv } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import { Scollling } from "./scrollservices";


export const Allserv = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  

  const HandleAll = () => {
    showAlert("The selected product has irregularity due to general server kindly check back later!", "success");
  };
  return (

    <>
    <div className="p-4 grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
      {/* Grid Items */}
      <button
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className="flex flex-col w-fit h-fit items-center bg-gray-50 text-white p-3 rounded-lg shadow-sm focus:outline-none"
      >
        <div className="bg-blue-500 p-2 rounded-full mb-2">
          <FaShoppingBag className="text-sm" />
        </div>
        <p className="font-bold text-xs text-center text-gray-950">Contact</p>
      </button>

      <button 
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className="flex flex-col items-center bg-gray-50 w-fit h-fit text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-red-300 p-2 rounded-full mb-2">
          <FaSalesforce className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-gray-950 text-center">Sell</p>
      </button>

      <button
        onClick={() => navigate('airtime')}
        className="flex flex-col items-center w-fit h-fit bg-gray-50 text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-blue-200 p-2 rounded-full mb-2">
          <FaPhone className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-grey-950 text-center">Airtime</p>
      </button>

      <button
        onClick={() => navigate("data")}
        className="flex flex-col items-center bg-gray-50 h-fit w-fit text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-teal-200 p-2 rounded-full mb-2">
          <FaWifi className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-gray-950 text-center">Data</p>
      </button>

      {/* New Service Item: Education */}
      <button
        onClick={HandleAll} 
        className="flex flex-col items-center bg-gray-50 h-fit w-fit text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-purple-200 p-2 rounded-full mb-2">
          <FaBook className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-center">Education</p>
      </button>

      <button
        onClick={HandleAll}
         className="flex flex-col items-center bg-gray-50 h-fit w-fit text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-yellow-200 p-2 rounded-full mb-2">
          <FaLightbulb className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-center">Electricity</p>
      </button>

      {/* New Service Item: Television */}
      <button
        onClick={HandleAll}
        className="flex flex-col items-center bg-gray-50 h-fit w-fit text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-purple-200 p-2 rounded-full mb-2">
          <FaTv className="text-sm"  />
        </div>
        <p className="font-bold text-xs text-center">Television</p>
      </button>

    
    </div>
    

<Scollling />
    </>
  );
};
