import React, { useState } from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";
import ModalSettings from "./modals";

export const Settings = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center">
        <h1 className="text-3xl font-bold text-darkblue mb-6">More Settings</h1>
        
        <div className="flex flex-col items-center space-y-6 mb-6">
          <button
            onClick={() => window.open("https://www.softnixx.com/api/password_reset/")}
            className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            Change Password
          </button>
          <button
            onClick={handleOpen}
            className="bg-green-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            Change Transaction PIN
          </button>
        </div>

        <div className="flex justify-center space-x-6">
          <a
            href="mailto:samuelyyyy257@gmail.com"
            className="bg-gray-800 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out"
          >
            <FaMailBulk className="text-2xl" />
          </a>
          <a
            href="https://wa.me/1234567890" // Replace with actual phone number
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            <FaWhatsapp className="text-2xl" />
          </a>
          <a
            href="#"
            className="bg-purple-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out"
          >
            <FaExplosion className="text-2xl" />
          </a>
        </div>
      </div>

      <ModalSettings open={modalOpen} onClose={handleClose} />
    </div>
  );
};
