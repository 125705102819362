import { useNavigate } from "react-router-dom";
import { useAlert } from "../alert/useAlert";
import { Outlet } from "react-router-dom";

export const Dashboard = () => {
  const navigate = useNavigate();
  const showMessage = useAlert();

  const Logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
    showMessage("Logout successfully", "success"); // Changed "error" to "success"
  };
  return (
    <>
  <div className="flex flex-col lg:flex-row justify-around">
    <div>
      {/* Sidebar for larger screens */}
      {/* Bottom tab bar for mobile */}
      <div className="fixed bottom-0 left-0  w-full z-50 bg-white text-blue-950 md:hidden">
      <div className="flex justify-around">
        {/* Home Button */}
        <button
          onClick={() => navigate('/home')}
          className="flex flex-col items-center py-2 px-4"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round"  strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
          </svg>
          <span className="text-xs mt-1">Home</span>
        </button>

        {/* Settings Button */}
        <button
          onClick={() => navigate('setting')}
          className="flex flex-col items-center py-2 px-4"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
          </svg>
          <span className="text-xs mt-1">Settings</span>
        </button>

        {/* Help Button */}
        <button
          onClick={() => navigate('help')}
          className="flex flex-col items-center py-2 px-4"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
          </svg>
          <span className="text-xs mt-1">Help</span>
        </button>
        <button
            onClick={() => navigate('notification')}
            className="flex flex-col items-center py-2 px-4"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
            </svg>
            <span className="text-xs mt-1">Notifications</span>
          </button>

        {/* Finance Button */}
        <button
          onClick={() => navigate('finance')}
          className="flex flex-col items-center py-2 px-4"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16m-7 4h7m-7 4h7"></path>
          </svg>
          <span className="text-xs mt-1">Finance</span>
        </button>
      </div>
    </div>
    
    <div >
    <Outlet />
    </div>
    </div>
    </div>
    </>
  );
};
