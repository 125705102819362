import React from "react";
import { FaShoppingBag, FaBitcoin, FaPhone, FaWifi, FaBook, FaSalesforce } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import Logo from '../../../src/comp.jpg'

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const Scollling = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  
  const Handlecrypto = () => {
    showAlert("Web3 feature coming soon!", "success");
  };

  const HandleAll = () => {
    showAlert("The selected product has irregularity due to general server kindly check back later!", "success");
  };

  const sections = [
    {
      title: "Download our App",
      content: "Stay connected",
      content2: "and get the best experience with our mobile app",
      titleClass: "text-xl font-semibold mb-2",
      contentClass: "text-gray-200 text-sm",
    },
    {
      title: "More from Softtellex",
      content: "Explore other services and offerings from Softtellex.",
      content2: "and get rewards using the app",
      titleClass: "text-xl font-semibold mb-2",
      contentClass: "text-gray-200 text-sm",
    },
    {
      title: "API Softtellex",
      content: "Explore our API and integrate it into your software soon!",
      content2: "Stay tuned for more updates!",
      titleClass: "text-xl font-semibold mb-3",
      contentClass: "text-gray-200 text-sm",
    },
    {
      title: "Vacancy",
      content: "Check out current job openings and join our team.",
      content2: "Stay tuned for more updates!",
      titleClass: "text-xl font-semibold mb-2",
      contentClass: "text-gray-200 text-sm",
    },
  ];

  return (
    <>
      {/* Horizontal Auto-Scrolling Sections */}
      <div className="overflow-x-auto whitespace-nowrap z-10 p-4 bg-gradient-to-r from-white via-gray-50 to-gray-500">
        <div className="flex space-x-6 p-2">
          {sections.map((section, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-80 p-6 rounded-lg shadow-lg"
              style={{ backgroundColor: getRandomColor() }}
            >
              <h3 className={`text-center ${section.titleClass} mb-2`}>
                {section.title}
              </h3>
              <p className={`text-center ${section.contentClass} mb-1`}>
                {section.content}
              </p>
              <p className={`text-center text-gray-300 text-xs`}>
                {section.content2}
              </p>
            </div>
          ))}
        </div>
       
      </div>
      <div>
        <div className="shadow-lg z-0 w-full bg-gray-600 rounded-lg text-center text-white right-0 left-0"><h3>
            Join the App users
        </h3>
        <p>
            And Explore more services
        </p></div>
        <div>

        <img src={Logo} className="object-cover -z-50 h-48 w-full rounded-lg"  />
        </div>
        
     
      </div>
     
    </>
  );
};
