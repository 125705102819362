import React, { useState, useEffect } from "react";
import "../home.css";
import {  CircularProgress } from "@mui/material";
import { Allserv } from "./renderAllservices";
import { Link } from "react-router-dom";
import ModalComponent from "./modal";
import { FaBars, FaEyeSlash, FaInfinity, FaNairaSign } from "react-icons/fa6";
import {
  FaBell,
  FaEye,
  FaHome,
  FaSignOutAlt,
} from "react-icons/fa";
import { useAlert } from "../../component/alert/useAlert";
import { FaPlus } from "react-icons/fa"; // Import the icon from react-icons
import { useNavigate } from "react-router-dom";
import Logo from "../../../src/comp.jpg"
import FadeIn from "../../component/text";

export const Service = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null); // Initialize as null instead of empty array
  const showMessage = useAlert();
  const [open, setOpen] = useState(false);
  const [eye, setEye] = useState(false)
  

  const navigate = useNavigate();


  const ToggleOpen = () => {
    setOpen(!open);
  };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("user");

  const getDetails = async () => {
    if (!token || !username) {
      showMessage("Something is missing...", "error");
      return;
    }

    setLoading(true);
    const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
    try {
      const response = await fetch(itemUrL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const json = await response.json();
            
      if (json.profile.paystack_id === null || json.profile.paystack_id ==="") {
        const customerUrl = 'https://api.paystack.co/customer';
        const customerData = {
          email: json.email,
          first_name: json.last_name,
          last_name: json.first_name,
          phone: json.phone,
        };
        const authorization = 'Bearer sk_live_ea796eb8e2daee044b8143ccc52c8c5f155da9d2';
        const customerResponse = await fetch(customerUrl, {
          method: 'POST',
          headers: {
            'Authorization': authorization,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(customerData),
        });
        if (!customerResponse.ok) {
          throw new Error(`Error creating Paystack customer: ${customerResponse.statusText}`);
        }
        const customerResult = await customerResponse.json();
        const createNewUrl = 'https://www.softnixx.com/api/create_new';
        const createNewResponse = await fetch(createNewUrl,{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            email: json.email,
            customer_id: customerResult.data.customer_code,
          }),
        });
        if (!createNewResponse.ok) {
          throw new Error(`Error sending data to server: ${createNewResponse.statusText}`);
        }
      }
      setData(json);
    } catch (error) {
      showMessage(`Error: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  },[]); 

  const formatLocaleString = (value) => {
    if (value != null) {
      return value.toLocaleString();
    }
    return "N/A";
  };




  const Logout = () => {
    localStorage.clear();
    showMessage("Logout succesfully..!", "error");
    navigate("/login");
  };



  const openEYE =()=>{
    setEye(!eye)
  }



  function capitalizeFirstLetter(string) {
    if(string){
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
    return "Hold On.."
    
}
  return (
    <>
      {open && (
        <FadeIn>
       <div
       className={`fixed top-0 left-0 z-10 h-screen w-2/3 lg:w-64 bg-white text-gray-950 p-4 flex flex-col transition-transform duration-300 ${
         open ? "translate-x-0" : "-translate-x-full"
       } lg:translate-x-0`}
     >
       <div className="flex items-center mb-6">
         <img
          src={Logo}
           alt="User Avatar"
           className="w-12 h-12 rounded-full mr-3 border-2 border-gray-500"
         />
         <div>
           <h2 className="text-xl font-semibold">{data?.username?.toUpperCase()}</h2>
           <p className="text-gray-400 text-sm">{data?.email}</p>
         </div>
       </div>
       <h2 className="text-sm font-bold mb-4">Softnixx web 1.5</h2>
       <h2 className="text-xl font-bold mb-6">Personal Dashboard</h2>
       <input
         type="text"
         placeholder="Search..."
         className="mb-4 w-full px-3 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
       />
       <ul className="flex-1 space-y-3">
         <li>
           <Link onClick={ToggleOpen} to="" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
             <FaHome className="mr-2" /> Home
           </Link>
         </li>
         <li>
           <Link onClick={ToggleOpen} to="finance" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
             <FaInfinity className="mr-2" /> Finance
           </Link>
         </li>
         <li>
           <Link onClick={Logout} to="/login" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
             <FaSignOutAlt className="mr-2" /> Logout
           </Link>
         </li>
         <li>
         <Link onClick={Logout} to="/delete" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
             <FaSignOutAlt className="mr-2" /> Delete Account
           </Link>
         </li>
       </ul>
     </div>
     </FadeIn>
      )}
      
      <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg">
        <FadeIn>
        <div className="flex flex-row justify-between align-middle content-center">
          <button
            onClick={ToggleOpen}
            className={`flex items-center p-2 rounded-full ${
              open ? "text-black" : "text-white"
            }`}
          >
            <FaBars
              className={`text-xl ${
                open
                  ? "bg-red-500 shadow-lg text-white"
                  : "text-gray-950 bg-white shadow-lg"
              }`}
            />
          </button>
          <h1 className="text-2l font-extrabold text-gray-600 ">Dashboard</h1>
          <div>
            <h6 className="text-red-500 font-bold rounded-lg shadow-sm absolute top-0 right-2">1</h6>
          <FaBell className="text-gray-500" />
          </div>
          
        </div>
        </FadeIn>
        <FadeIn>
        <div className="flex flex-row justify-left shadow-sm mt-6 items-center">
          <h3 className="font-bold text-sm mr-2 ">Welcome Back,</h3>
          <div>
           {capitalizeFirstLetter(data?.username)}
          </div>
        </div>
        </FadeIn>
        <FadeIn>
        <div>
        <div className="bg-darkblue text-gray-950 p-6 rounded-xl shadow-md justify-between mb-6 flex flex-row items-center">
      
          <div className="text-sm text-gray-950 font-semibold">
            {loading? <CircularProgress />

                : <h2 className="flex flex-row items-center text-gray-700 text-balance"><FaNairaSign /> { eye?formatLocaleString(data?.profile?.account_balance):"* * * *"} {eye?<FaEyeSlash  onClick={openEYE} className=" ml-6 "/>:<FaEye onClick={openEYE}  className=" ml-6 "/>}  </h2>
            }
              <div>
       <h4 className="bg-blue-600 w-fit flex flex-row items-center p-2 rounded-lg">
        <FaPlus className="text-sm text-white" />
            <button onClick={handleOpen} className=" text-sm  text-white">
              Deposit
            </button>
            </h4>
       </div>
          </div>
       <div className="bg-gray-100 text-gray-950 text-sm w-fit p-2 rounded-sm">
        <button onClick={()=>showMessage("please download the app to convert")}> Point {data?.profile?.point_bonus}</button>
 
</div>
        </div>
        </div>
        </FadeIn>
<FadeIn>
        <div>
          <Allserv />
        </div>
        </FadeIn>
        <FadeIn>
        <ModalComponent open={modalOpen} onClose={handleClose} />
        </FadeIn>
      </div>
    
    </>
  );
};
